/* Main container for the header and terminal */
.main-container {
    width: 100%;
    height: 100vh; /* Full viewport height */
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #000;
    padding: 0;
    margin: 0;  /* Remove any margins */
  }
  
  /* Header section */
  .header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .header pre {
    color: #FFFFFF;
  }
  
  .links {
    margin-top: 10px;
  }
  
  .links a {
    color: #FFFFFF;
    margin-left: 10px;
    text-decoration: none;
  }
  
  .links a:hover {
    text-decoration: underline;
  }
  
  /* Terminal container */
  .terminal-container {
    flex-grow: 1; /* Allow the terminal to grow and fill remaining space */
    width: 80%;
    max-width: 1000px;
    background-color: #000;
    color: #FFFFFF;
    border: 2px solid #FFFFFF;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 0 20px #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;  /* Make sure the terminal sticks to the top */
    margin: 0 auto;
  }
  
  /* Image container for girly.png */
  .image-container {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .ai-image {
    width: 300px; /* Adjust the size of the image */
    height: auto;
    border-radius: 5px;
  }
  
  /* Terminal window */
  .terminal {
    flex-grow: 1; /* Allow terminal to take up the available space */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-top: 1px solid #FFFFFF;
    padding-top: 10px;
    overflow: hidden;
  }
  
  .output {
    flex-grow: 1; /* Make the output area grow and fill available space */
    overflow-y: auto;
    margin-bottom: 10px;
    padding-right: 10px;
  }
  
  .output p {
    margin: 0;
  }
  
  /* Input area */
  .input-area {
    display: flex;
    align-items: center;
  }
  
  input {
    background-color: transparent;
    border: none;
    color: #FFFFFF;
    font-size: 16px;
    width: 100%;
    outline: none;
  }
  
  .send-button {
    background-color: #FFFFFF;
    color: #000;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    margin-left: 10px;
    font-family: 'Press Start 2P', monospace;
  }
  
  .send-button:hover {
    background-color: #FFFFFF;
  }
  
  .send-button:active {
    background-color: #FFFFFF;
  }
  